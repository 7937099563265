import { bonjoappApiClient } from "backend";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

// VenueMemberships.js
export const VenueMemberships = createCRUDPage(
  "venuememberships-crud",
  bonjoappApiClient,
  "Bonjo Venue Memberships",
  "bonjoapp"
);

// VenueMembershipsStatus.js
export const VenueMembershipsStatus = createCRUDPage(
  "venuemembershipsstatus-crud",
  bonjoappApiClient,
  "Bonjo Venue Memberships Status",
  "bonjoapp"
);
