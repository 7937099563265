import { bonjoappApiClient } from "backend";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

// For BUserBVenues
export const BUserBVenues = createCRUDPage(
  "buserbvenues-crud",
  bonjoappApiClient,
  "Bonjo User Venues",
  "bonjoapp"
);

// For BVenueBadges
export const BVenueBadges = createCRUDPage(
  "bvenuebadges-crud",
  bonjoappApiClient,
  "Bonjo Venue Badges",
  "bonjoapp"
);

// For BVenueCapacities
export const BVenueCapacities = createCRUDPage(
  "bvenuecapacities-crud",
  bonjoappApiClient,
  "Bonjo Venue Capacities",
  "bonjoapp"
);

// For BVenueFeatures
export const BVenueFeatures = createCRUDPage(
  "bvenuefeatures-crud",
  bonjoappApiClient,
  "Bonjo Venue Features",
  "bonjoapp"
);

// For BVenuePaymentTypes
export const BVenuePaymentTypes = createCRUDPage(
  "bvenuepaymenttypes-crud",
  bonjoappApiClient,
  "Bonjo Venue Payment Types",
  "bonjoapp"
);

// For BVenuePhotoGalleries
export const BVenuePhotoGalleries = createCRUDPage(
  "bvenuephotogalleries-crud",
  bonjoappApiClient,
  "Bonjo Venue Photo Galleries",
  "bonjoapp"
);

// For BVenueProducts
export const BVenueProducts = createCRUDPage(
  "bvenueproducts-crud",
  bonjoappApiClient,
  "Bonjo Venue Products",
  "bonjoapp"
);

// For BVenueTypes
export const BVenueTypes = createCRUDPage(
  "bvenuetypes-crud",
  bonjoappApiClient,
  "Bonjo Venue Types",
  "bonjoapp"
);

// For BVenueTeammembers
export const BVenueTeammembers = createCRUDPage(
  "bvenueteammembers-crud",
  bonjoappApiClient,
  "Bonjo Venue Team Members",
  "bonjoapp"
);

// For InviteTokenTeammembers
export const InviteTokenTeammembers = createCRUDPage(
  "invitetokenteammembers-crud",
  bonjoappApiClient,
  "Invite Token Team Members",
  "bonjoapp"
);

// For Teammembers
export const Teammembers = createCRUDPage(
  "teammembers-crud",
  bonjoappApiClient,
  "Teammembers",
  "bonjoapp"
);

// For BVenues
export const BVenues = createCRUDPage(
  "bvenues-crud",
  bonjoappApiClient,
  "Bonjo Venues",
  "bonjoapp"
);

// For BonjoBusinessUser
export const BonjoBusinessUser = createCRUDPage(
  "bonjobusinessuser-crud",
  bonjoappApiClient,
  "Bonjo Business User",
  "bonjoapp"
);
