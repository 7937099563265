import { bonjoappApiClient } from "backend";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

// BonjoCoupons.js
export const BonjoCoupons = createCRUDPage(
  "bonjocoupons-crud",
  bonjoappApiClient,
  "Bonjo Coupons",
  "bonjoapp"
);

// BonjoCoupons.js
export const BonjoCouponProducts = createCRUDPage(
  "bonjocouponproducts-crud",
  bonjoappApiClient,
  "Bonjo Coupons",
  "bonjoapp"
);
