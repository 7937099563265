import React from "react";
import { Card } from "antd";
import CountUp from "react-countup";
import { styled } from "styled-components";
import { ArrowUpRight } from "lucide-react";
import { size } from "lodash";

// Styled components
const TextMuted = styled.div`
  color: ${({ theme }) => theme.token.textMute};
`;

const BgInfo = styled.div`
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.token.colorInfoBg};
`;

const SingleWidget = ({
  title,
  value,
  percentageChange,
  icon: Icon,
  bgColor,
  increase = true,
}) => {
  return (
    <Card style={{ marginLeft: "20px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <p style={{ textTransform: "uppercase", fontSize: "13px" }}>
            {title}
          </p>
          <h4 style={{ fontSize: "22px", marginBottom: "10px" }}>
            {" "}
            <CountUp
              start={0}
              end={value}
              duration={0.5}
              decimal="."
              decimals={2}
            />
          </h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h5
              style={{
                fontSize: "12px",
                color: increase ? "green" : "red",
                alignItems: "center",
                marginBottom: "0",
              }}
            >
              <ArrowUpRight size={13} />{" "}
              {increase ? `+${percentageChange}%` : `${percentageChange}%`}
            </h5>
            <TextMuted style={{ marginBottom: "0", marginLeft: "4px" }}>
              {" "}
              than last week
            </TextMuted>
          </div>
        </div>
        <div>
          <BgInfo
            style={{
              padding: "6px",
              width: "48px",
              height: "48px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon style={{ color: bgColor, width: "24px", height: "24px" }} />
          </BgInfo>
        </div>
      </div>
    </Card>
  );
};

export default SingleWidget;
