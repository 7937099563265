import { datacenterApiClient } from "backend";

export const ACCOUNT_LOGIN = "/account/login";
export const GET_USERS = "/user/";
export const GET_ACCOUNTS = "/account/";
export const ADD_ACCOUNT = "/account/register";
export const GET_SESSION_DATA = "/sampler/get-session-data/";

export const userLogin = async (data) => {
  const newObject = {
    ...data,
    product_group: "DASHBOARD", // Dynamic key
  };

  const response = await datacenterApiClient.post(ACCOUNT_LOGIN, newObject);
  return response;
};

export const dropVenues = async () => {
  const response = await datacenterApiClient.post("/venue/drop-collection", {});
  return response;
};

export const getAccounts = async () => {
  const response = await datacenterApiClient.get(GET_ACCOUNTS);
  console.log(response.data);
  return response;
};

export const getSessionData = async (session_id) => {
  console.log(GET_SESSION_DATA + session_id);
  const response = await datacenterApiClient.get(GET_SESSION_DATA + session_id);
  console.log(response.data);
  return response.data;
};

export const addAccount = async (data) => {
  const response = await datacenterApiClient.post(ADD_ACCOUNT, data);
  console.log(response.data);
  return response;
};

export const getUsers = async () => {
  const response = await datacenterApiClient.get(GET_USERS);
  console.log(response.data);
  return response;
};

export const getSessions = async () => {
  const response = await datacenterApiClient.get(
    GET_USERS + "?email_exists=false"
  );
  return response;
};

export const getWifiMaps = async () => {
  return [
    { title: "Total Earnings", value: 745.35, percentageChange: 18.3 },
    { title: "Orders", value: 698.36, percentageChange: -2.74 },
    { title: "Customers", value: 183.35, percentageChange: -29.08 },
    { title: "Sales", value: 1245.5, percentageChange: 5.5 },
    { title: "Sales", value: 1245.5, percentageChange: -5.5 },
  ];
};

export const getSessionWidgetData = async (setSessionWidgetData) => {
  return setSessionWidgetData([
    { title: "Total Earnings", value: 745.35, percentageChange: 18.3 },
    { title: "Orders", value: 698.36, percentageChange: -2.74 },
    { title: "Customers", value: 183.35, percentageChange: -29.08 },
    { title: "Sales", value: 1245.5, percentageChange: 5.5 },
    { title: "Sales", value: 1245.5, percentageChange: -5.5 },
  ]);
};

export const getVenueGeofencesWidgetData = async (
  setVenueGeofencesWidgetData
) => {
  return setVenueGeofencesWidgetData([
    { title: "Total Earnings", value: 745.35, percentageChange: 18.3 },
    { title: "Orders", value: 698.36, percentageChange: -2.74 },
    { title: "Customers", value: 183.35, percentageChange: -29.08 },
    { title: "Sales", value: 1245.5, percentageChange: 5.5 },
    { title: "Sales", value: 1245.5, percentageChange: -5.5 },
  ]);
};

export const getDealGeofencesWidgetData = async (
  setDealGeofencesWidgetData
) => {
  return setDealGeofencesWidgetData([
    { title: "Total Earnings", value: 745.35, percentageChange: 18.3 },
    { title: "Orders", value: 698.36, percentageChange: -2.74 },
    { title: "Sales", value: 1245.5, percentageChange: 5.5 },
    { title: "Sales", value: 1245.5, percentageChange: -5.5 },
  ]);
};

export const getVenueWidgetData = async (setVenueWidgetData) => {
  return setVenueWidgetData([
    { title: "Total Earnings", value: 745.35, percentageChange: 18.3 },
    { title: "Orders", value: 698.36, percentageChange: -2.74 },
    { title: "Customers", value: 183.35, percentageChange: -29.08 },
    { title: "Sales", value: 1245.5, percentageChange: 5.5 },
    { title: "Sales", value: 1245.5, percentageChange: -5.5 },
  ]);
};
