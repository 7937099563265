import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import WidgetGroup from "./SessionWidgets";
import usecustomStyles from "Common/customStyles";
import { Map, Store, TabletSmartphoneIcon } from "lucide-react";
import { IoLogoApple, IoLogoAndroid } from "react-icons/io";
import { datacenterApiClient } from "backend";
import {
  getDealGeofencesWidgetData,
  getSessionWidgetData,
  getVenueGeofencesWidgetData,
  getVenueWidgetData,
} from "pages/Apps/Datacenter/DataCenterAPIClient";

const customStyles = usecustomStyles();

const DatacenterDashboard = () => {
  document.title = "The Door Dashboard";

  const [sessionWidgetData, setSessionWidgetData] = useState([]);
  const [venueGeofencesWidgetData, setVenueGeofencesWidgetData] = useState([]);
  const [dealGeofencesWidgetData, setDealGeofencesWidgetData] = useState([]);
  const [venueWidgetData, setVenueWidgetData] = useState([]);

  useEffect(() => {
    getSessionWidgetData(setSessionWidgetData);
    getVenueGeofencesWidgetData(setVenueGeofencesWidgetData);
    getDealGeofencesWidgetData(setDealGeofencesWidgetData);
    getVenueWidgetData(setVenueWidgetData);
  }, []);

  return (
    <>
      <div>
        <Row gutter={[24, 24]}>
          <WidgetGroup widgetsData={sessionWidgetData} icon={IoLogoApple} />
        </Row>
        <Row gutter={[24, 24]}>
          <WidgetGroup widgetsData={sessionWidgetData} icon={IoLogoAndroid} />
        </Row>
        <Row gutter={[24, 24]}>
          <WidgetGroup widgetsData={venueGeofencesWidgetData} icon={Map} />
        </Row>
        <Row gutter={[24, 24]}>
          <WidgetGroup widgetsData={dealGeofencesWidgetData} icon={Map} />
        </Row>

        <Row gutter={[24, 24]}>
          <WidgetGroup widgetsData={venueWidgetData} icon={Store} />
        </Row>
      </div>
    </>
  );
};

export default DatacenterDashboard;
