// @ts-nocheck
import React from "react";
import { Card, Col, Row } from "antd";
import createCRUDFunctions from "../../CRUD/CRUDFunctions";
import CRUDComponent from "pages/CRUD/CRUDComponent";
import ContentHeader from "pages/Apps/ContentHeader";
import usecustomStyles from "../../../Common/customStyles";

// Higher-order function to create a CRUD component
const createCRUDPage = (
  modelName,
  client,
  title,
  customerCode = null, // Add customerCode parameter
  specialRenderedComponents = {},
  doNotDisplayComponents = {},
  filterableColumns = [],
  readOnly = false
) => {
  return function CRUDPage() {
    document.title = title;
    const customStyles = usecustomStyles();
    console.log("createCRUDPage", customerCode);

    return (
      <>
        <ContentHeader headerTitle={title} />

        <Row gutter={[24]} style={{ marginTop: "-20px" }}>
          <Col xs={24} className="gutter-row" style={{ overflowX: "auto" }}>
            <Card
              style={{ marginBottom: customStyles.margin, overflowX: "auto" }}
            >
              <CRUDComponent
                modelName={modelName}
                client={client}
                readOnly={readOnly}
                createCRUDFunctions={createCRUDFunctions}
                specialRenderedComponents={specialRenderedComponents}
                doNotDisplayComponents={doNotDisplayComponents}
                customerCode={customerCode}
                filterableColumns={filterableColumns}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  };
};

export default createCRUDPage;
