import { bonjoappApiClient } from "backend";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

// For SysBaseCompanyRoles
export const SysBaseCompanyRoles = createCRUDPage(
  "sysbasecompanyroles-crud",
  bonjoappApiClient,
  "Company Roles",
  "bonjoapp"
);

// For SysBaseVenueFeatures
export const SysBaseVenueFeatures = createCRUDPage(
  "sysbasevenuefeatures-crud",
  bonjoappApiClient,
  "Venue Features",
  "bonjoapp"
);

// For SysBaseComminicationMethods
export const SysBaseComminicationMethods = createCRUDPage(
  "sysbasecomminicationmethods-crud",
  bonjoappApiClient,
  "Communication Methods",
  "bonjoapp"
);

// For SysBaseVenuePaymentTypes
export const SysBaseVenuePaymentTypes = createCRUDPage(
  "sysbasevenuepaymenttypes-crud",
  bonjoappApiClient,
  "Venue Payment Types",
  "bonjoapp"
);

// For SysBaseProducts
export const SysBaseProducts = createCRUDPage(
  "sysbaseproducts-crud",
  bonjoappApiClient,
  "Products",
  "bonjoapp"
);

// For SysBaseVenueBadges
export const SysBaseVenueBadges = createCRUDPage(
  "sysbasevenuebadges-crud",
  bonjoappApiClient,
  "Venue Badges",
  "bonjoapp"
);

// For SysBaseKeyCategories
export const SysBaseKeyCategories = createCRUDPage(
  "sysbasekeycategories-crud",
  bonjoappApiClient,
  "Loyalty Key Kategorileri",
  "bonjoapp"
);

// For SysBaseKeyCategoriesProducts
export const SysBaseKeyCategoriesProducts = createCRUDPage(
  "sysbasekeycategoriesproducts-crud",
  bonjoappApiClient,
  "Loyalty Key Kategori Ürünleri",
  "bonjoapp"
);

// For SysBaseNotifications
export const SysBaseNotifications = createCRUDPage(
  "sysbasenotifications-crud",
  bonjoappApiClient,
  "Bildirim Başlıkları",
  "bonjoapp"
);

// For SysBaseNotificationTypes
export const SysBaseNotificationTypes = createCRUDPage(
  "sysbasenotificationtypes-crud",
  bonjoappApiClient,
  "Bildirim Tipi Başlıkları",
  "bonjoapp"
);

// For SysBaseCommunicationMethods
export const SysBaseCommunicationMethods = createCRUDPage(
  "sysbasecommunicationmethods-crud",
  bonjoappApiClient,
  "İletişim Tipi Başlıkları",
  "bonjoapp"
);

// For SysBaseVenueTypes
export const SysBaseVenueTypes = createCRUDPage(
  "sysbasevenuetypes-crud",
  bonjoappApiClient,
  "İşletme Kategorileri",
  "bonjoapp"
);

// For SysBaseMemberships
export const SysBaseMemberships = createCRUDPage(
  "sysbasememberships-crud",
  bonjoappApiClient,
  "SysBaseMemberships",
  "bonjoapp"
);

// For SysBaseToken
export const SysBaseToken = createCRUDPage(
  "sysbasetoken-crud",
  bonjoappApiClient,
  "SysBaseToken",
  "bonjoapp"
);

// For SysBaseToken
export const BonjoAgents = createCRUDPage(
  "bonjoagents-crud",
  bonjoappApiClient,
  "SysBaseToken",
  "bonjoapp"
);

// For Countries
export const Countries = createCRUDPage(
  "countries-crud",
  bonjoappApiClient,
  "Ülkeler",
  "bonjoapp"
);

// For Cities
export const Cities = createCRUDPage(
  "cities-crud",
  bonjoappApiClient,
  "Şehirler",
  "bonjoapp"
);

// For Counties
export const Counties = createCRUDPage(
  "counties-crud",
  bonjoappApiClient,
  "İlçeler",
  "bonjoapp"
);
