import React from "react";
import { Col, Row } from "antd";

//Breadcrumb
// import Breadcrumb from '../../Common/Breadcrumb';
import usecustomStyles from "../../Common/customStyles";
import Widget from "./Widget";
import MoreSale from "./MoreSale";
import RecentOrder from "./RecentOrder";
import BestSellingProduct from "./BestSellingProduct";
import StockReport from "./StockReport";
import TopRetailShop from "./TopRetailShop";
import RecentChat from "./RecentChat";
import Revenue from "./Revenue";
import Salebycategory from "./Salebycategory";

const customStyles = usecustomStyles();
// const { Text } = Typography;

const Dashboard = () => {
  document.title = "Dashboard" + process.env.REACT_APP_PAGE_TITLE;

  return (
    <>
      <div>
        <Row gutter={[24, 24]}>
          <Widget />
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
