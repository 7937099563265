import { bonjoappApiClient } from "backend";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

// Loyalties.js
export const Loyalties = createCRUDPage(
  "loyalties-crud",
  bonjoappApiClient,
  "Bonjo Loyalties",
  "bonjoapp"
);

export const LoyaltyKeys = createCRUDPage(
  "loyaltykeys-crud",
  bonjoappApiClient,
  "Bonjo Loyalty Keys",
  "bonjoapp"
);

export const CustomLoyaltyKeyProducts = createCRUDPage(
  "customloyaltykeyproducts-crud",
  bonjoappApiClient,
  "Bonjo Custom Loyalty Key Products",
  "bonjoapp"
);

export const LoyaltiesUpdateRequests = createCRUDPage(
  "loyaltiesupdaterequests-crud",
  bonjoappApiClient,
  "Bonjo Loyalties Update Requests",
  "bonjoapp"
);
