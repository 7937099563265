import { bonjoappApiClient } from "backend";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

// For BonjoUser
export const BonjoUser = createCRUDPage(
  "bonjouser-crud",
  bonjoappApiClient,
  "Bonjo Users",
  "bonjoapp",
  {}, // empty object for specialRenderedComponents (or provide your own)
  {}, // empty object for doNotDisplayComponents (or provide your own)
  [], // empty array for filterableColumns (or provide your own)
  true // explicitly set readOnly to true
);

// For UserNotificationTypes
export const UserNotificationTypes = createCRUDPage(
  "usernotificationtypes-crud",
  bonjoappApiClient,
  "User Notification Types",
  "bonjoapp",
  {}, // empty object for specialRenderedComponents (or provide your own)
  {}, // empty object for doNotDisplayComponents (or provide your own)
  [], // empty array for filterableColumns (or provide your own)
  true // explicitly set readOnly to true
);

// For UserCheckIn
export const UserCheckIn = createCRUDPage(
  "usercheckin-crud",
  bonjoappApiClient,
  "User Check Ins",
  "bonjoapp"
);

// For UserCommunicationMethods
export const UserCommunicationMethods = createCRUDPage(
  "usercommunicationmethods-crud",
  bonjoappApiClient,
  "User Communication Methods",
  "bonjoapp",
  {}, // empty object for specialRenderedComponents (or provide your own)
  {}, // empty object for doNotDisplayComponents (or provide your own)
  [], // empty array for filterableColumns (or provide your own)
  true // explicitly set readOnly to true
);

// For UserVenueCheckIn
export const UserVenueCheckIn = createCRUDPage(
  "uservenuecheckin-crud",
  bonjoappApiClient,
  "User Venue Check Ins",
  "bonjoapp"
);

// For UserVenueView
export const UserVenueView = createCRUDPage(
  "uservenueview-crud",
  bonjoappApiClient,
  "User Venue Views",
  "bonjoapp",
  {}, // empty object for specialRenderedComponents (or provide your own)
  {}, // empty object for doNotDisplayComponents (or provide your own)
  [], // empty array for filterableColumns (or provide your own)
  true // explicitly set readOnly to true
);

// For UserContactUs
export const UserContactUs = createCRUDPage(
  "usercontactus-crud",
  bonjoappApiClient,
  "User Contact Us",
  "bonjoapp",
  {}, // empty object for specialRenderedComponents (or provide your own)
  {}, // empty object for doNotDisplayComponents (or provide your own)
  [], // empty array for filterableColumns (or provide your own)
  true // explicitly set readOnly to true
);

// For UserVenueSuggestion
export const UserVenueSuggestion = createCRUDPage(
  "uservenuesuggestion-crud",
  bonjoappApiClient,
  "User Venue Suggestions",
  "bonjoapp"
);
