// DATACENTER URLS

const createCRUDFunctions = (modelName, client, customerCode = null) => {
  // Base URL, optionally including customerCode

  const baseUrl = customerCode
    ? `/${customerCode}/${modelName}`
    : `/${modelName}`;

  console.log(customerCode, baseUrl);

  const getColumns = async () => {
    // let res = await getAllItems();
    // if (res.items.length > 0) {
    //   return Object.keys(res.items[0]);
    // } else {
    try {
      const response = await client.get(`${baseUrl}/columns`);
      let column_names = response.data.map((item) => {
        return item.name;
      });

      let column_type = response.data.map((item) => {
        return item.type;
      });

      let column_required = response.data.map((item) => {
        return item.required;
      });

      console.log("Get All Columns:", {
        column_names,
        column_type,
        column_required,
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching columns for ${modelName}:`, error);
      throw error;
    }
    // }
  };

  const getUniqueColumnValues = async (columnName) => {
    try {
      const response = await client.get(
        `${baseUrl}/getUniqueValueColumn/${columnName}`
      );
      console.log("getUniqueColumnValues", response.data);
      return response.data;
    } catch (error) {
      console.error(`Error fetching columns for ${modelName}:`, error);
      throw error;
    }
  };

  const getAllItems = async ({ limit = 10, offset = 0, filters = {} } = {}) => {
    console.log("filters", filters);
    if (Object.keys(filters).length === 0) {
      try {
        const response = await client.get(`${baseUrl}/`, {
          params: { limit, offset },
        });
        return {
          items: response.data.items,
          total: parseInt(response.data.total, 10),
        };
      } catch (error) {
        console.error(`Error fetching items for ${modelName}:`, error);
        throw error;
      }
    } else {
      try {
        const response = await client.post(
          `${baseUrl}/filter?limit=${limit}&offset=${offset}`,
          {
            filters: filters,
          }
        );

        console.log(response);

        return {
          items: response.data.items,
          total: parseInt(response.data.total, 10),
        };
      } catch (error) {
        console.error(`Error fetching items for ${modelName}:`, error);
        throw error;
      }
    }

    // }
  };

  const addItem = async (item) => {
    try {
      const response = await client.post(`${baseUrl}/`, item);
      console.log("Added Item:", response.data);
      return response.data;
    } catch (error) {
      console.error(`Error adding item to ${modelName}:`, error);
      throw error;
    }
  };

  const updateItem = async (itemId, updatedItem) => {
    try {
      const response = await client.put(`${baseUrl}/${itemId}`, updatedItem);
      console.log("Updated Item:", response.data);
      return response.data;
    } catch (error) {
      console.error(`Error updating item in ${modelName}:`, error);
      throw error;
    }
  };

  const deleteItem = async (itemId) => {
    try {
      const response = await client.delete(`${baseUrl}/${itemId}`);
      console.log("Deleted Item:", response.data);
      return response.data;
    } catch (error) {
      console.error(`Error deleting item from ${modelName}:`, error);
      throw error;
    }
  };

  return {
    getColumns,
    getAllItems,
    addItem,
    updateItem,
    deleteItem,
    getUniqueColumnValues,
  };
};

export default createCRUDFunctions;
