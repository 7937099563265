import { bonjoappApiClient } from "backend";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

// Key.js
export const Keys = createCRUDPage(
  "key-crud",
  bonjoappApiClient,
  "Bonjo Keys",
  "bonjoapp"
);

// KeyRedemptionRule.js
export const KeyRedemptionRules = createCRUDPage(
  "keyredemptionrule-crud",
  bonjoappApiClient,
  "Bonjo Key Redemption Rules",
  "bonjoapp"
);

// KeyRedemption.js
export const KeyRedemptions = createCRUDPage(
  "keyredemption-crud",
  bonjoappApiClient,
  "Bonjo Key Redemptions",
  "bonjoapp"
);

// BVenueKeyDistributes.js
export const BVenueKeyDistributes = createCRUDPage(
  "bvenuekeydistributes-crud",
  bonjoappApiClient,
  "Bonjo Venue Key Distributes",
  "bonjoapp"
);

// BonjoUserKeypoints.js
export const BonjoUserKeypoints = createCRUDPage(
  "bonjouserkeypoints-crud",
  bonjoappApiClient,
  "Bonjo User Keypoints",
  "bonjoapp"
);

// BVenueKeyRedeems.js
export const BVenueKeyRedeems = createCRUDPage(
  "bvenuekeyredeems-crud",
  bonjoappApiClient,
  "Bonjo Venue Key Redeems",
  "bonjoapp"
);

// BonjoUserKeyRedeems.js
export const BonjoUserKeyRedeems = createCRUDPage(
  "bonjouserkeyredeems-crud",
  bonjoappApiClient,
  "Bonjo User Key Redeems",
  "bonjoapp"
);
