import React from "react";
import { Row } from "antd";

import usecustomStyles from "Common/customStyles";
import SingleWidget from "./SingleWidget";

const customStyles = usecustomStyles();

const WidgetGroup = ({ widgetsData, icon }) => {
  return (
    <Row gutter={[24, 24]} style={{ marginTop: customStyles.margin }}>
      {widgetsData.map((widget, index) => {
        const increase = widget.percentageChange >= 0;
        const bgColor = increase
          ? customStyles.colorSuccess
          : customStyles.colorDanger;

        return (
          <SingleWidget
            key={index}
            title={widget.title}
            value={widget.value}
            percentageChange={widget.percentageChange}
            icon={icon}
            bgColor={bgColor}
            increase={increase}
          />
        );
      })}
    </Row>
  );
};

export default WidgetGroup;
