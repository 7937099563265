import React, { useEffect, useState, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import "leaflet-draw";
import { Modal, Button } from "antd";

const FitBounds = ({ bounds }) => {
  const map = useMap();
  useEffect(() => {
    if (bounds.length) {
      const leafletBounds = L.latLngBounds(bounds);
      map.fitBounds(leafletBounds);
    }
  }, [bounds, map]);

  return null;
};

const DrawGeofence = ({ addGeofence, updateGeofence, removeGeofence, drawnItems, customerCodes, geofenceTypes }) => {
  const map = useMap();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pendingGeofence, setPendingGeofence] = useState({
    latitude: null,
    longitude: null,
    radius: null,
    customer_code: customerCodes.length > 0 ? customerCodes[0] : "thedoor",
    geofence_type: geofenceTypes.length > 0 ? geofenceTypes[0] : "TEST",
  });

  useEffect(() => {
    const drawControl = new L.Control.Draw({
      edit: {
        featureGroup: drawnItems.current,
        remove: true,
      },
      draw: {
        marker: false,
        circlemarker: false,
        polygon: false,
        polyline: false,
        rectangle: false,
        circle: {
          shapeOptions: {
            color: "yellow",
            fillOpacity: 0.3,
          },
        },
      },
    });

    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, (event) => {
      const layer = event.layer;
      const radius = layer.getRadius();
      const center = layer.getLatLng();

      setPendingGeofence({
        latitude: center.lat,
        longitude: center.lng,
        radius,
        customer_code: customerCodes[0] || "",
        geofence_type: geofenceTypes[0] || "",
      });

      setIsModalVisible(true);
      drawnItems.current.addLayer(layer);
    });

    return () => {
      map.removeControl(drawControl);
    };
  }, [map, addGeofence, updateGeofence, removeGeofence, drawnItems, customerCodes, geofenceTypes]);

  const handleCreateGeofence = () => {
    if (pendingGeofence) {
      addGeofence(pendingGeofence)
        .then((savedGeofence) => {
          if (savedGeofence?.id) {
            setPendingGeofence({
              latitude: null,
              longitude: null,
              radius: null,
              customer_code: customerCodes[0] || "",
              geofence_type: geofenceTypes[0] || "",
            });
          }
        })
        .catch((error) => {
          console.error("Failed to create geofence:", error);
        })
        .finally(() => {
          // Ensure the modal closes regardless of success or error.
          setIsModalVisible(false);
        });
    }
  };
  

  return (
    <>
      <Modal
        title="Creating New Geofence"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="create" type="primary" onClick={handleCreateGeofence}>
            Create
          </Button>,
        ]}
      >
        <p>
          You are about to create a new geofence at Latitude: {pendingGeofence?.latitude},
          Longitude: {pendingGeofence?.longitude} with Radius: {pendingGeofence?.radius}
        </p>
        <div>
          <label>Customer Code: </label>
          <select
            value={pendingGeofence.customer_code}
            onChange={(e) =>
              setPendingGeofence((prev) => ({ ...prev, customer_code: e.target.value }))
            }
          >
            {customerCodes.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </select>
        </div>
        <div style={{ marginTop: "10px" }}>
          <label>Geofence Type: </label>
          <select
            value={pendingGeofence.geofence_type}
            onChange={(e) =>
              setPendingGeofence((prev) => ({ ...prev, geofence_type: e.target.value }))
            }
          >
            {geofenceTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
      </Modal>
    </>
  );
};

const GeofenceMapComponent = ({ geofences, addGeofence, updateGeofence, removeGeofence, customerCodes, geofenceTypes }) => {
  const drawnItems = useRef(new L.FeatureGroup());
  const center = geofences?.length
    ? [
        geofences.reduce((sum, gf) => sum + gf.latitude, 0) / geofences.length,
        geofences.reduce((sum, gf) => sum + gf.longitude, 0) / geofences.length,
      ]
    : [0, 0];

  const bounds = geofences?.map((gf) => [gf.latitude, gf.longitude]);

  useEffect(() => {
    if (!drawnItems.current) return;

    drawnItems.current.clearLayers();

    geofences.forEach((gf) => {
      const layer = L.circle([gf.latitude, gf.longitude], {
        radius: gf.radius,
        color: "blue",
        fillColor: "blue",
        fillOpacity: 0.3,
        id: gf.id,
      });

      layer.bindPopup(`
        <div>
          <strong>Geofence Details</strong><br />
          <strong>Latitude:</strong> ${gf.latitude}<br />
          <strong>Longitude:</strong> ${gf.longitude}<br />
          <strong>Radius:</strong> ${gf.radius} meters<br />
          <strong>Customer Code:</strong> ${gf.customer_code || "N/A"}<br />
          <strong>Type:</strong> ${gf.geofence_type || "N/A"}<br />
        </div>
      `);

      drawnItems.current.addLayer(layer);
    });
  }, [geofences]);

  return (
    <MapContainer
      center={center}
      zoom={10}
      style={{ height: "500px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <FeatureGroup ref={drawnItems} />

      <FitBounds bounds={bounds} />
      <DrawGeofence
        addGeofence={addGeofence}
        updateGeofence={updateGeofence}
        removeGeofence={removeGeofence}
        drawnItems={drawnItems}
        customerCodes={customerCodes}
        geofenceTypes={geofenceTypes}
      />
    </MapContainer>
  );
};

export default GeofenceMapComponent;