import { bonjoappApiClient } from "backend";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

// Profile.js
export const Profile = createCRUDPage(
  "profile-crud",
  bonjoappApiClient,
  "Bonjo Profiles",
  "bonjoapp",
  {}, // empty object for specialRenderedComponents (or provide your own)
  {}, // empty object for doNotDisplayComponents (or provide your own)
  [], // empty array for filterableColumns (or provide your own)
  true // explicitly set readOnly to true
);

// FrequentlyAskedQuestion.js
export const FrequentlyAskedQuestions = createCRUDPage(
  "frequentlyaskedquestion-crud",
  bonjoappApiClient,
  "Bonjo Frequently Asked Questions",
  "bonjoapp",
  {}, // empty object for specialRenderedComponents (or provide your own)
  {}, // empty object for doNotDisplayComponents (or provide your own)
  [], // empty array for filterableColumns (or provide your own)
  false // explicitly set readOnly to true
);

// AppComments.js
export const AppComments = createCRUDPage(
  "appcomments-crud",
  bonjoappApiClient,
  "Bonjo App Comments",
  "bonjoapp",
  {}, // empty object for specialRenderedComponents (or provide your own)
  {}, // empty object for doNotDisplayComponents (or provide your own)
  [], // empty array for filterableColumns (or provide your own)
  true // explicitly set readOnly to true
);

// ProfileFavorites.js
export const ProfileFavorites = createCRUDPage(
  "profilefavorites-crud",
  bonjoappApiClient,
  "Bonjo Profile Favorites",
  "bonjoapp",
  {}, // empty object for specialRenderedComponents (or provide your own)
  {}, // empty object for doNotDisplayComponents (or provide your own)
  [], // empty array for filterableColumns (or provide your own)
  true // explicitly set readOnly to true
);

// ProfileFavoriteLists.js
export const ProfileFavoriteLists = createCRUDPage(
  "profilefavoritelists-crud",
  bonjoappApiClient,
  "Bonjo Profile Favorite Lists",
  "bonjoapp",
  {}, // empty object for specialRenderedComponents (or provide your own)
  {}, // empty object for doNotDisplayComponents (or provide your own)
  [], // empty array for filterableColumns (or provide your own)
  true // explicitly set readOnly to true
);

// ProfileFavoriteListsFavorites.js
export const ProfileFavoriteListsFavorites = createCRUDPage(
  "profilefavoritelistsfavorites-crud",
  bonjoappApiClient,
  "Bonjo Profile Favorite Lists Favorites",
  "bonjoapp",
  {}, // empty object for specialRenderedComponents (or provide your own)
  {}, // empty object for doNotDisplayComponents (or provide your own)
  [], // empty array for filterableColumns (or provide your own)
  true // explicitly set readOnly to true
);
