import usecustomStyles from "Common/customStyles";
import React, { useEffect, useState } from "react";
import ContentHeader from "../ContentHeader";
import { Card, Col, Row } from "antd";

import GeofenceMapComponent from "../Core/GeofenceMapComponent";
import CRUDComponent from "../../CRUD/CRUDComponent"; // Ensure the correct path
import createCRUDFunctions from "../../CRUD/CRUDFunctions";
import { datacenterApiClient } from "backend";

const Geofences = (props) => {
  const customStyles = usecustomStyles();
  const modelName = "geofence-crud";
  const [geofences, setGeofences] = useState([]);
  const [customerCodes, setCustomerCodes] = useState(["thedoor", "bonjoapp"]); // Default customer_code values
  const [geofenceTypes, setGeofenceTypes] = useState(["TEST", "VENUE", "DEAL", "CAMPAIGN", "USER", "OTHER"]); // Default geofence_type values
  const [updateItself, setUpdateItself] = useState(false);

  const {
    getColumns,
    getAllItems,
    addItem,
    updateItem,
    deleteItem,
    getUniqueColumnValues,
  } = createCRUDFunctions(modelName, datacenterApiClient, props.customerCode);

  const newGeofenceDrawn = async (newGeofence) => {
    try {
      const createdGeofence = await addItem(newGeofence); // Backend returns created geofence with ID
      setGeofences((prevGeofences) => [...prevGeofences, createdGeofence]);
      setUpdateItself(!updateItself);
    } catch (error) {
      console.error("Error adding geofence:", error);
    }
  };

  const handleRemoveGeofence = async (id) => {
    try {
      await deleteItem(id);
      setGeofences((prevGeofences) => prevGeofences.filter((gf) => gf.id !== id));
      setUpdateItself(!updateItself);
    } catch (error) {
      console.error("Error removing geofence:", error);
    }
  };

  const handleUpdateGeofence = async (id, updatedGeofence) => {
    try {
      const updated = await updateItem(id, updatedGeofence); // Backend returns updated geofence
      setGeofences((prevGeofences) =>
        prevGeofences.map((gf) =>
          gf.id === updated.id
            ? { ...gf, latitude: updated.latitude, longitude: updated.longitude, radius: updated.radius }
            : gf
        )
      );
      setUpdateItself(!updateItself);
    } catch (error) {
      console.error("Error updating geofence:", error);
    }
  };

  const loadData = async () => {
    try {
      const data = await getAllItems({ limit: 100 }); // Limit to 100 geofences temporarily
      console.log("Backend returned geofences:", data?.items);

      const visibleGeofences = data?.items || [];
      setGeofences(visibleGeofences);
    } catch (error) {
      console.error("Error loading geofences:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <ContentHeader headerTitle={"Geofences"} />

      <Row gutter={[24]} style={{ marginTop: "-20px" }}>
        <Col xs={24} className="gutter-row" style={{ overflowX: "auto" }}>
          <Card
            style={{ marginBottom: customStyles.margin, overflowX: "auto" }}
          >
            <CRUDComponent
              modelName={modelName}
              client={datacenterApiClient}
              createCRUDFunctions={createCRUDFunctions}
              updateItself={updateItself}
              dataChangedNotifier={loadData}
              customerCode={props.customerCode}
              geofences={geofences}
              setGeofences={setGeofences}
            />
          </Card>
          <Card
            style={{
              marginBottom: customStyles.margin,
              marginTop: "20px",
              overflowX: "auto",
            }}
          >
            <GeofenceMapComponent
              geofences={geofences}
              setGeofences={setGeofences}
              addGeofence={newGeofenceDrawn}
              updateGeofence={handleUpdateGeofence}
              removeGeofence={handleRemoveGeofence}
              customerCodes={customerCodes}
              geofenceTypes={geofenceTypes}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Geofences;