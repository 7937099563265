// @ts-ignore
import { Navigate } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
//General
import UiButtons from "../pages/Components/General/Buttons";
import UiTypography from "../pages/Components/General/Typographys";
//Navigation
import UiAnchor from "../pages/Components/Navigation/Anchor";
import UiBreadcrumb from "../pages/Components/Navigation/Breadcrumb";
import UiDropdown from "../pages/Components/Navigation/Dropdown";
import UiPagination from "../pages/Components/Navigation/Pagination";
import UiSteps from "../pages/Components/Navigation/Steps";
//
import Avatars from "../pages/Components/DataDisplay/Avatar";
import Badges from "../pages/Components/DataDisplay/Badge";
import Calenders from "../pages/Components/DataDisplay/Calendar";
import Cards from "../pages/Components/DataDisplay/Cards";
import Carousels from "../pages/Components/DataDisplay/Carousel";
import UiCollapse from "../pages/Components/DataDisplay/Collapse";
import UiDescriptions from "../pages/Components/DataDisplay/Descriptions";
import UiEmptyImages from "../pages/Components/DataDisplay/emptyImages";
import UiList from "../pages/Components/DataDisplay/List";
import UiPopover from "../pages/Components/DataDisplay/Popover";
import UiQRCode from "../pages/Components/DataDisplay/QRCode";
import UiSegmented from "../pages/Components/DataDisplay/Segmented";
import UiStatistic from "../pages/Components/DataDisplay/Statistic";
import UiTable from "../pages/Components/DataDisplay/Table/Table";
import UiTabs from "../pages/Components/DataDisplay/Tabs";
import UiTag from "../pages/Components/DataDisplay/Tag";
import UiTimeline from "../pages/Components/DataDisplay/Timeline";
import UiTooltip from "../pages/Components/DataDisplay/Tooltip";
import UiTour from "../pages/Components/DataDisplay/Tour";
import UiTree from "../pages/Components/DataDisplay/Tree/Tree";
import Alerts from "../pages/Components/Feedback/Alerts";
import UiDrawer from "../pages/Components/Feedback/Drawer";
import UiMessage from "../pages/Components/Feedback/Message";
import UiModal from "../pages/Components/Feedback/Modal";
import UiNotification from "../pages/Components/Feedback/Notification";
import UiPopconfirm from "../pages/Components/Feedback/Popconfirm";
import UiProgress from "../pages/Components/Feedback/Progress";
import UiResult from "../pages/Components/Feedback/Result";
import UiSkeleton from "../pages/Components/Feedback/Skeleton";
import UiSpin from "../pages/Components/Feedback/Spin";
import UiAutoComplete from "../pages/Components/DataEntry/AutoComplete";
import UiCascader from "../pages/Components/DataEntry/Cascader";
import UiCheckbox from "../pages/Components/DataEntry/Checkbox";
import UiColorPicker from "../pages/Components/DataEntry/ColorPicker";
import UiDatePicker from "../pages/Components/DataEntry/DatePicker";
import UiForm from "../pages/Components/DataEntry/Form";
import UiInput from "../pages/Components/DataEntry/Input";
import UiInputNumber from "../pages/Components/DataEntry/InputNumber";
import UiMentions from "../pages/Components/DataEntry/Mentions";
import UiRadio from "../pages/Components/DataEntry/Radio";
import UiRate from "../pages/Components/DataEntry/Rate";
import UiSelect from "../pages/Components/DataEntry/Select";
import UiSlider from "../pages/Components/DataEntry/Slider";
import UiSwitch from "../pages/Components/DataEntry/Switch";
import UiTimePicker from "../pages/Components/DataEntry/TimePicker";
import UiTransfer from "../pages/Components/DataEntry/Transfer";
import UiTreeSelect from "../pages/Components/DataEntry/TreeSelect";

//charts routes
import BasicAreachart from "../pages/Charts/AreaChart/BasicAreaChart";
import StackedArea from "../pages/Charts/AreaChart/StackedAreaChart";
import BasicLineChart from "../pages/Charts/LineChart/BasicLineChart";
import MultiLineCharts from "../pages/Charts/LineChart/MultiLineChart";
import StepLineChart from "../pages/Charts/LineChart/StepLineChart";
import BasicHistogram from "../pages/Charts/ColumnChart/BasicChart/Index";
import GroupedHistogram from "../pages/Charts/ColumnChart/GroupedChart";
import PercentageHistogram from "../pages/Charts/ColumnChart/PercentageChart";
import BasicBarCharts from "../pages/Charts/BarChart/BasicBar";
import StackedBarChart from "../pages/Charts/BarChart/StackedBar";
import GroupBarChart from "../pages/Charts/BarChart/GroupBar";
import PieCharts from "../pages/Charts/PieChart/Pie";
import RingDiagramChart from "../pages/Charts/PieChart/RingDiagram";
import DashboardProgressChart from "../pages/Charts/ProgressChart/DashboardChart";
import WaterWaveCharts from "../pages/Charts/ProgressChart/WaterWaveMap";
import BulletCharts from "../pages/Charts/ProgressChart/BulletChart";
import ScatterPlotcharts from "../pages/Charts/ScatterBubbleChart/ScatterPlot";
import Bubblecharts from "../pages/Charts/ScatterBubbleChart/BubbleCharts";

import StackedColumn from "../pages/Charts/StackedColumn";
import PluginTextEditor from "../pages/plugins/textEditor";
import AuthSignUp from "../pages/AuthenticationInner/SignUp";
import PagesUserProfile from "../pages/pages/UserProfile";
import PercentageArea from "../pages/Charts/AreaChart/PercentageAreaChart/PercentageArea";
import SignIn from "../pages/AuthenticationInner/SignIn";
import Forms from "../pages/plugins/Forms/Index";
import ClipBoard from "../pages/plugins/Clipboard";
import Textloop from "../pages/plugins/TextLoop";
import Video from "../pages/plugins/Video";
import Animation from "../pages/plugins/Animation";
import FaqPage from "../pages/pages/FAQ";
import Pricing from "../pages/pages/Pricing";
import TeamPage from "../pages/pages/Team";
import Timelinepage from "../pages/pages/Timeline";
import Widgets from "../pages/Widgets";
import PasswordReset from "../pages/AuthenticationInner/PasswordReset";
import PasswordCreate from "../pages/AuthenticationInner/PasswordCreate";
import LockScreen from "../pages/AuthenticationInner/Lock-Screen";
import TwoStepVerification from "../pages/AuthenticationInner/TwoStepVerification";
import SuccessMessage from "../pages/AuthenticationInner/SuccessMessage";
import Fourzerofour from "../pages/ErrorPages/404";
import Fivehundred from "../pages/ErrorPages/500";
import FourzerofourAlt from "../pages/ErrorPages/404Alt";
import Offline from "../pages/ErrorPages/Offline";
import Maintenance from "../pages/pages/Maintenance";
import ApiKey from "../pages/Apps/ApiKey";
import ContactPage from "../pages/Apps/Contact";
import UserPage from "../pages/Apps/User";
import Todo from "../pages/Apps/To-Do";
import EmailPage from "../pages/Apps/Email";
import Signin from "../pages/Authentication/login";
import Logout from "../pages/Authentication/logout";
import UserProfile from "../pages/Authentication/user-profile";
import Register from "../pages/Authentication/register";
import Forgetpassword from "../pages/Authentication/forgetpassword";
import ChatPage from "../pages/Apps/Chat";
import AntdIcon from "../pages/Components/Icons/AntdIcon";
import LucideIcon from "../pages/Components/Icons/LucideIcon";
import KanbanBoard from "../pages/Apps/KanbanBoard";
import LeaderBoard from "../pages/Apps/LeaderBoard";
import WifiMaps from "../pages/Apps/Datacenter/WifiMaps";
import BleMaps from "../pages/Apps/Datacenter/BleMaps";
import { Sessions } from "../pages/Apps/Datacenter/Sessions";
import Venues from "../pages/Apps/Datacenter/Venues";
import SpeakeasyProfile from "../pages/Apps/Speakeasy/Profile/SpeakeasyProfile";
import SpeakeasyUser from "../pages/Apps/Speakeasy/User/SpeakeasyUser";
// @ts-ignore

// @ts-ignore
import {
  BonjoAccounts,
  DatacenterAccounts,
} from "pages/Apps/Datacenter/Accounts";
import React from "react";
import Geofences from "pages/Apps/Datacenter/Geofences";
import {
  BUserBVenues,
  BVenueBadges,
  BVenueCapacities,
  BVenueFeatures,
  BVenuePaymentTypes,
  BVenuePhotoGalleries,
  BVenueProducts,
  BVenueTypes,
  BVenueTeammembers,
  InviteTokenTeammembers,
  Teammembers,
  BVenues,
  BonjoBusinessUser,
} from "pages/Apps/Bonjo/BonjoBusinessModels";
import {
  BonjoUser,
  UserCheckIn,
  UserCommunicationMethods,
  UserContactUs,
  UserNotificationTypes,
  UserVenueCheckIn,
  UserVenueSuggestion,
  UserVenueView,
} from "pages/Apps/Bonjo/BonjoUser";
import {
  BonjoAgents,
  Cities,
  Counties,
  Countries,
  SysBaseComminicationMethods,
  SysBaseCommunicationMethods,
  SysBaseCompanyRoles,
  SysBaseKeyCategories,
  SysBaseKeyCategoriesProducts,
  SysBaseMemberships,
  SysBaseNotificationTypes,
  SysBaseNotifications,
  SysBaseProducts,
  SysBaseToken,
  SysBaseVenueBadges,
  SysBaseVenueFeatures,
  SysBaseVenuePaymentTypes,
  SysBaseVenueTypes,
} from "pages/Apps/Bonjo/Entities";
import {
  BVenueKeyDistributes,
  BVenueKeyRedeems,
  BonjoUserKeyRedeems,
  BonjoUserKeypoints,
  KeyRedemptionRules,
  KeyRedemptions,
  Keys,
} from "pages/Apps/Bonjo/Keys";
import {
  VenueMemberships,
  VenueMembershipsStatus,
} from "pages/Apps/Bonjo/VenueMembership";
import {
  AppComments,
  FrequentlyAskedQuestions,
  Profile,
  ProfileFavoriteLists,
  ProfileFavoriteListsFavorites,
  ProfileFavorites,
} from "pages/Apps/Bonjo/Profile";
import { QRCodes } from "pages/Apps/Bonjo/QR";
import {
  CustomLoyaltyKeyProducts,
  Loyalties,
  LoyaltiesUpdateRequests,
  LoyaltyKeys,
} from "pages/Apps/Bonjo/Loyalty";
import { BonjoCouponProducts, BonjoCoupons } from "pages/Apps/Bonjo/Coupons";
import DatacenterDashboard from "TheDoor/pages/Dashboard";
import BonjoDashboard from "Bonjo/pages/Dashboard";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/", exact: true, component: <Navigate to="/bonjoapp-venues" /> },
  { path: "*", component: <Navigate to="/bonjoapp-venues" /> },

  //pages routes
  { path: "/pages-profile", component: <PagesUserProfile /> },
  { path: "/pages-pricing", component: <Pricing /> },
  { path: "/pages-faqs", component: <FaqPage /> },
  { path: "/pages-team", component: <TeamPage /> },
  { path: "/pages-timeline", component: <Timelinepage /> },

  // Apps
  { path: "/app-email", component: <EmailPage /> },
  { path: "/app-chat", component: <ChatPage /> },
  { path: "/app-kanban", component: <KanbanBoard /> },
  { path: "/app-contact", component: <ContactPage /> },
  { path: "/app-user", component: <UserPage /> },
  { path: "/app-apikey", component: <ApiKey /> },
  { path: "/app-todo", component: <Todo /> },
  { path: "/app-leaderboard", component: <LeaderBoard /> },

  // Widget
  { path: "/widget", component: <Widgets /> },

  //General routes
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-typography", component: <UiTypography /> },
  //navigation routes
  { path: "/ui-anchor", component: <UiAnchor /> },
  { path: "/ui-breadcrumb", component: <UiBreadcrumb /> },
  { path: "/ui-dropdown", component: <UiDropdown /> },
  { path: "/ui-pagination", component: <UiPagination /> },
  { path: "/ui-steps", component: <UiSteps /> },
  //data entry routes
  { path: "/ui-autocomplete", component: <UiAutoComplete /> },
  { path: "/ui-cascader", component: <UiCascader /> },
  { path: "/ui-checkbox", component: <UiCheckbox /> },
  { path: "/ui-colorpicker", component: <UiColorPicker /> },
  { path: "/ui-datepicker", component: <UiDatePicker /> },
  { path: "/ui-form", component: <UiForm /> },
  { path: "/ui-input", component: <UiInput /> },
  { path: "/ui-input-number", component: <UiInputNumber /> },
  { path: "/ui-mentions", component: <UiMentions /> },
  { path: "/ui-radio", component: <UiRadio /> },
  { path: "/ui-rate", component: <UiRate /> },
  { path: "/ui-select", component: <UiSelect /> },
  { path: "/ui-slider", component: <UiSlider /> },
  { path: "/ui-switch", component: <UiSwitch /> },
  { path: "/ui-timepicker", component: <UiTimePicker /> },
  { path: "/ui-transfer", component: <UiTransfer /> },
  { path: "/ui-tree-select", component: <UiTreeSelect /> },
  //data display routes
  { path: "/ui-avatar", component: <Avatars /> },
  { path: "/ui-badge", component: <Badges /> },
  { path: "/ui-calender", component: <Calenders /> },
  { path: "/ui-cards", component: <Cards /> },
  { path: "/ui-carousel", component: <Carousels /> },
  { path: "/ui-collapse", component: <UiCollapse /> },
  { path: "/ui-descriptions", component: <UiDescriptions /> },
  { path: "/ui-empty-images", component: <UiEmptyImages /> },
  { path: "/ui-list", component: <UiList /> },
  { path: "/ui-popover", component: <UiPopover /> },
  { path: "/ui-qrcode", component: <UiQRCode /> },
  { path: "/ui-segmented", component: <UiSegmented /> },
  { path: "/ui-statistic", component: <UiStatistic /> },
  { path: "/ui-table", component: <UiTable /> },
  { path: "/ui-tabs", component: <UiTabs /> },
  { path: "/ui-tag", component: <UiTag /> },
  { path: "/ui-timeline", component: <UiTimeline /> },
  { path: "/ui-tooltip", component: <UiTooltip /> },
  { path: "/ui-tour", component: <UiTour /> },
  { path: "/ui-tree", component: <UiTree /> },
  //Feedback routes
  { path: "/ui-alerts", component: <Alerts /> },

  { path: "/ui-drawer", component: <UiDrawer /> },
  { path: "/ui-message", component: <UiMessage /> },
  { path: "/ui-modal", component: <UiModal /> },
  { path: "/ui-notification", component: <UiNotification /> },
  { path: "/ui-popconfirm", component: <UiPopconfirm /> },
  { path: "/ui-progress", component: <UiProgress /> },
  { path: "/ui-result", component: <UiResult /> },
  { path: "/ui-skeleton", component: <UiSkeleton /> },
  { path: "/ui-spin", component: <UiSpin /> },
  //chart's
  { path: "/charts-basic", component: <BasicLineChart /> },
  { path: "/charts-multi-line", component: <MultiLineCharts /> },
  { path: "/charts-step-line", component: <StepLineChart /> },
  { path: "/ui-Basic-Area", component: <BasicAreachart /> },
  { path: "/ui-stacked-area", component: <StackedArea /> },
  { path: "/ui-percentage-area", component: <PercentageArea /> },
  { path: "/ui-Basic-histogram", component: <BasicHistogram /> },
  { path: "/ui-stacked-column", component: <StackedColumn /> },
  { path: "/ui-Grouped-histogram", component: <GroupedHistogram /> },
  { path: "/ui-Percentage-histogram", component: <PercentageHistogram /> },
  { path: "/ui-Basic-bar-chart", component: <BasicBarCharts /> },
  { path: "/ui-stacked-bar", component: <StackedBarChart /> },
  { path: "/ui-grouped-bar", component: <GroupBarChart /> },
  { path: "/ui-pie-chart", component: <PieCharts /> },
  { path: "/ui-Ring-diagram", component: <RingDiagramChart /> },
  { path: "/ui-Dash-board", component: <DashboardProgressChart /> },
  { path: "/ui-water-wave-map", component: <WaterWaveCharts /> },
  { path: "/ui-bubble-chart", component: <Bubblecharts /> },

  //plugins routes
  { path: "/plugins-text-editor", component: <PluginTextEditor /> },
  { path: "/plugins-forms", component: <Forms /> },
  { path: "/plugins-clipboard", component: <ClipBoard /> },
  { path: "/plugins-text-loop", component: <Textloop /> },
  { path: "/plugins-video", component: <Video /> },
  { path: "/plugins-animation", component: <Animation /> },

  //icons
  { path: "/icon-antd", component: <AntdIcon /> },
  { path: "/icon-lucide", component: <LucideIcon /> },

  // { path: "/ui-Ring-diagram", component: <RingDiagram /> },
  { path: "/ui-Basic-Bullet-Chart", component: <BulletCharts /> },
  { path: "/ui-Scatterplot", component: <ScatterPlotcharts /> },

  // user profile
  { path: "/user-profile", component: <UserProfile /> },

  // { path: "/", component: <Dashboard /> },
  // { path: "/", exact: true, component: <Navigate to="/dashboard" /> },

  //DATACENTER

  {
    path: "/datacenter-dashboard",
    component: <DatacenterDashboard />,
  },

  {
    path: "/datacenter-accounts",
    component: <DatacenterAccounts />,
  },

  {
    path: "/datacenter-venues",
    component: <Venues />,
  },

  {
    path: "/datacenter-sessions",
    component: <Sessions customerCode="thedoor" />,
  },
  // {
  //   path: "/datacenter-session",
  //   component: <Session />,
  // },
  {
    path: "/datacenter-wifimaps",
    component: <WifiMaps />,
  },

  {
    path: "/datacenter-blemaps",
    component: <BleMaps />,
  },

  {
    path: "/datacenter-geofences",
    component: <Geofences customerCode="thedoor" />,
  },

  ////////////
  // BONJO
  ////////////

  // BONJO BUSINESS MODELS
  {
    path: "/bonjo-dashboard",
    component: <BonjoDashboard />,
  },
  {
    path: "/bonjoapp-buservenues",
    component: <BUserBVenues />,
  },
  {
    path: "/bonjoapp-bvenuebadges",
    component: <BVenueBadges />,
  },
  {
    path: "/bonjoapp-bvenuecapacities",
    component: <BVenueCapacities />,
  },
  {
    path: "/bonjoapp-bvenuefeatures",
    component: <BVenueFeatures />,
  },
  {
    path: "/bonjoapp-bvenuepaymenttypes",
    component: <BVenuePaymentTypes />,
  },
  {
    path: "/bonjoapp-bvenuephotogalleries",
    component: <BVenuePhotoGalleries />,
  },
  {
    path: "/bonjoapp-bvenueproducts",
    component: <BVenueProducts />,
  },
  {
    path: "/bonjoapp-bvenuetypes",
    component: <BVenueTypes />,
  },
  {
    path: "/bonjoapp-bvenueteammembers",
    component: <BVenueTeammembers />,
  },
  {
    path: "/bonjoapp-invitetokenteammembers",
    component: <InviteTokenTeammembers />,
  },
  {
    path: "/bonjoapp-teammembers",
    component: <Teammembers />,
  },
  {
    path: "/bonjoapp-bvenues",
    component: <BVenues />,
  },
  {
    path: "/bonjoapp-businessuser",
    component: <BonjoBusinessUser />,
  },

  // BONJO USER

  {
    path: "/bonjoapp-bonjouser",
    component: <BonjoUser />,
  },
  {
    path: "/bonjoapp-usernotificationtypes",
    component: <UserNotificationTypes />,
  },
  {
    path: "/bonjoapp-usercheckin",
    component: <UserCheckIn />,
  },
  {
    path: "/bonjoapp-usercommunicationmethods",
    component: <UserCommunicationMethods />,
  },
  {
    path: "/bonjoapp-uservenuecheckin",
    component: <UserVenueCheckIn />,
  },
  {
    path: "/bonjoapp-uservenueview",
    component: <UserVenueView />,
  },
  {
    path: "/bonjoapp-usercontactus",
    component: <UserContactUs />,
  },
  {
    path: "/bonjoapp-uservenuesuggestion",
    component: <UserVenueSuggestion />,
  },

  // COUPONS

  {
    path: "/bonjoapp-bonjocoupons",
    component: <BonjoCoupons />,
  },
  {
    path: "/bonjoapp-bonjocouponproducts",
    component: <BonjoCouponProducts />,
  },

  // BONJO ENTITIES
  {
    path: "/bonjoapp-syscompanyroles",
    component: <SysBaseCompanyRoles />,
  },
  {
    path: "/bonjoapp-sysvenuefeatures",
    component: <SysBaseVenueFeatures />,
  },
  {
    path: "/bonjoapp-syscommunicationmethods",
    component: <SysBaseComminicationMethods />,
  },
  {
    path: "/bonjoapp-sysvenuepaymenttypes",
    component: <SysBaseVenuePaymentTypes />,
  },
  {
    path: "/bonjoapp-sysproducts",
    component: <SysBaseProducts />,
  },
  {
    path: "/bonjoapp-sysvenuebadges",
    component: <SysBaseVenueBadges />,
  },
  {
    path: "/bonjoapp-syskeycategories",
    component: <SysBaseKeyCategories />,
  },
  {
    path: "/bonjoapp-syskeycategoriesproducts",
    component: <SysBaseKeyCategoriesProducts />,
  },
  {
    path: "/bonjoapp-sysnotifications",
    component: <SysBaseNotifications />,
  },
  {
    path: "/bonjoapp-sysnotificationtypes",
    component: <SysBaseNotificationTypes />,
  },
  {
    path: "/bonjoapp-syscommunicationmethods",
    component: <SysBaseCommunicationMethods />,
  },
  {
    path: "/bonjoapp-sysvenuetypes",
    component: <SysBaseVenueTypes />,
  },
  {
    path: "/bonjoapp-sysmemberships",
    component: <SysBaseMemberships />,
  },
  {
    path: "/bonjoapp-systoken",
    component: <SysBaseToken />,
  },
  {
    path: "/bonjoapp-agents",
    component: <BonjoAgents />,
  },
  {
    path: "/bonjoapp-countries",
    component: <Countries />,
  },
  {
    path: "/bonjoapp-cities",
    component: <Cities />,
  },
  {
    path: "/bonjoapp-counties",
    component: <Counties />,
  },

  // KEYS

  {
    path: "/bonjoapp-keys",
    component: <Keys />,
  },
  {
    path: "/bonjoapp-keyredemptionrules",
    component: <KeyRedemptionRules />,
  },
  {
    path: "/bonjoapp-keyredemptions",
    component: <KeyRedemptions />,
  },
  {
    path: "/bonjoapp-bvenuekeydistributes",
    component: <BVenueKeyDistributes />,
  },
  {
    path: "/bonjoapp-userkeypoints",
    component: <BonjoUserKeypoints />,
  },
  {
    path: "/bonjoapp-bvenuekeyredeems",
    component: <BVenueKeyRedeems />,
  },
  {
    path: "/bonjoapp-userkeyredeems",
    component: <BonjoUserKeyRedeems />,
  },

  // BONJO LOYALTY

  {
    path: "/bonjoapp-loyalties",
    component: <Loyalties />,
  },
  {
    path: "/bonjoapp-loyaltykeys",
    component: <LoyaltyKeys />,
  },
  {
    path: "/bonjoapp-customloyaltykeyproducts",
    component: <CustomLoyaltyKeyProducts />,
  },
  {
    path: "/bonjoapp-loyaltiesupdaterequests",
    component: <LoyaltiesUpdateRequests />,
  },

  // PROFILE

  {
    path: "/bonjoapp-profile",
    component: <Profile />,
  },
  {
    path: "/bonjoapp-frequentlyaskedquestions",
    component: <FrequentlyAskedQuestions />,
  },
  {
    path: "/bonjoapp-appcomments",
    component: <AppComments />,
  },
  {
    path: "/bonjoapp-profilefavorites",
    component: <ProfileFavorites />,
  },
  {
    path: "/bonjoapp-profilefavoritelists",
    component: <ProfileFavoriteLists />,
  },
  {
    path: "/bonjoapp-profilefavoritelistsfavorites",
    component: <ProfileFavoriteListsFavorites />,
  },

  // QRs
  {
    path: "/bonjoapp-qrcodes",
    component: <QRCodes />,
  },

  // VENUE MEMBERSHIP
  {
    path: "/bonjoapp-venuememberships",
    component: <VenueMemberships />,
  },
  {
    path: "/bonjoapp-venuemembershipstatus",
    component: <VenueMembershipsStatus />,
  },

  {
    path: "/bonjoapp-accounts",
    component: <BonjoAccounts />,
  },
  {
    path: "/bonjoapp-venues",
    component: <Venues />,
  },
  {
    path: "/bonjoapp-sessions",
    component: <Sessions customerCode={"bonjoapp"} />,
  },

  //SPEAKEASY
  { path: "/speakeasy-user", component: <SpeakeasyUser /> },
  { path: "/speakeasy-profile", component: <SpeakeasyProfile /> },
  { path: "/speakeasy-chat", component: <ChatPage /> },
];

const publicRoutes = [
  // Authentication
  { path: "/login", component: <Signin /> },
  { path: "/logout", component: <Logout /> },
  { path: "/register", component: <Register /> },
  { path: "/forgot-password", component: <Forgetpassword /> },

  // Authentication Inner
  { path: "/auth-signin", component: <SignIn /> },
  { path: "/auth-signup", component: <AuthSignUp /> },
  // { path: "/auth-signout", component: < /> },
  { path: "/auth-pass-reset", component: <PasswordReset /> },
  { path: "/auth-pass-create", component: <PasswordCreate /> },
  { path: "/auth-lockscreen", component: <LockScreen /> },
  { path: "/auth-two-step", component: <TwoStepVerification /> },
  { path: "/auth-success-msg", component: <SuccessMessage /> },

  // Error
  { path: "/error-404", component: <Fourzerofour /> },
  { path: "/error-500", component: <Fivehundred /> },
  { path: "/error-404-alt", component: <FourzerofourAlt /> },
  { path: "/error-offline", component: <Offline /> },

  // Pages
  { path: "/pages-maintenance", component: <Maintenance /> },
  // { path: "/pages-comingsoon", component: <Offline /> },

  // Auth- Inner
];

export { publicRoutes, authProtectedRoutes };
